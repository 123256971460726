<template>
  <div class="brandBox">
    <mobileHeader />
    <div class="coop">
      <img src="../../assets/m_dianshanbg.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.commerce.bannerTitle") }}
      </div>
    </div>
    <div class="branContainer">
      <div class="detail">
        {{ $t("lang.commerce.detailOne") }}{{ $t("lang.commerce.detailTwo") }}
      </div>
      <div class="importDetail">{{ $t("lang.commerce.importDetail") }}</div>
      <div class="partContainer">
        <div class="titles">{{ $t("lang.commerce.coreTitle") }}</div>
        <div class="box">
          <div class="part">
            <img src="../../assets/part1.jpg" />
            <div class="content">{{ $t("lang.commerce.contentOne") }}</div>
          </div>
          <div class="part">
            <img src="../../assets/part2.jpg" />
            <div class="content">{{ $t("lang.commerce.contentTwo") }}</div>
          </div>
          <div class="part">
            <img src="../../assets/part3.jpg" />
            <div class="content">{{ $t("lang.commerce.contentThree") }}</div>
          </div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "commerceOperation",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.brandBox {
  .coop {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .branContainer {
    padding: 0 25px 20px;
    margin-top: 50px;
    .detail {
      font-size: 16px;
      line-height: 25px;
      color: #6d7276;
    }
    .importDetail {
      font-size: 16px;
      color: #212b35;
      font-weight: bold;
      line-height: 25px;
    }
  }
  .partContainer {
    margin-top: 35px;
    .titles {
      margin-bottom: 30px;
      font-size: 20px;
      color: #212b35;
      letter-spacing: 0;
      font-weight: bold;
    }
    .box {
      margin: 40px 0;
      .part {
        margin-bottom: 30px;
        img {
          width: 100%;
        }
        //line-height: 0;
        .content {
          width: 100%;
          height: 120px;
          background: #21c3b8;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          padding: 0 23px;
          box-sizing: border-box;
          text-align: center;
        }
        .content:nth-child(2) {
          background: #618fab;
        }
        img:nth-child(2n + 1) {
          display: block;
        }
      }
    }
  }
}
</style>
